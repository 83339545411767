<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：每天价格-毛利计算</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8" v-show="true">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="状态:" label-width="60px">
                                    <el-select ref="state_stock"
                                               v-model="formData.state_stock"
                                               default-first-option
                                               remote
                                               multiple
                                               collapse-tags
                                               style="width: 100%"
                                               clearable filterable size="mini"
                                               placeholder="可搜索查询">
                                        <el-option
                                                v-for="item in state_stock_list"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="6">
                                <el-button style=" margin-top: 8px;margin-left: 8px " type="primary" round
                                           @click="searchProfits" size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openBill($refs.xGrid.getCurrentRecord())" size="mini">修改销售开单
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openTypeJC($refs.xGrid.getCurrentRecord())" size="mini">修改进仓单
                                </el-button>
                                <vxe-toolbar class="pull-right" style=" height: 42px; " custom export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <!--:merge-cells="mergeCells"-->
                        <!--:span-method="mergeRowMethod"-->
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                id="xGridEveryDayPriceProfits"
                                ref="xGrid"
                                align="center"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-current-row
                                :print-config="{}"
                                :export-config=tableExport
                                :custom-config="{storage:true}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                show-footer
                                :merge-cells="mergeCells"
                                :mouse-config="{selected: false}"
                                :footer-method="footerMethod"
                                :row-class-name="tableRowClassName"
                                :edit-config="{showIcon:false,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000,500000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {


        data() {
            // 通用行合并函数（将相同多列数据合并为一行）

            return {
                state_stock_list: [{value: "无限价", label: "无限价"}, {value: "订货", label: "订货"}, {value: "手动", label: "手动"}, {value: "自动", label: "自动"}],
                /* {row: 1, col: 9, rowspan: 2, colspan: 1},*/
                mergeCells: [],
                formData: {//搜索筛选
                    state_stock:[],//状态筛选
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                //visible:false,
                tableColumn: [
                    {field:'id', title: '销售ID', width: 70, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'state_stock', width: 60, title: '状态',
                        titleHelp: {message: '订单日期（无时分秒） = 限价日期 ：手动 \n例如：订单日期 2023-5-1 9:30:12 如果存在限价日期 2023-5-1 xx:xx:xx则为：手动\n如果有历史限价：自动 如果没有任何限价：无限价'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'sales_man', width: 70, title: '业务员',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'customer', width: 150, title: '客户',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'bill_stock_date',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')} , width: 150, title: '开单日期',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'bill_id_stock', width: 160, title: '销售单号',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'specification', width: 150, title: '实际规格',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'full_thick', width: 80, title: '足厚', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'single_count', width: 70, title: '支数',
                        titleHelp: {message: '销售单的支数'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'warehouse_out', width: 70, title: '仓库',
                        titleHelp: {message: '销售单的仓库'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'supplier', width: 150, title: '供应商',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'bill_id_stock_jc', width: 160, title: '进仓单号',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'batch_number_jc', width: 150, title: '批号',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'category', width: 50, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'actual_a_price_no_tax', width: 156, title: '实际销售支价(不含税)',
                        titleHelp: {message: '销售开单的实际销售支价(不含税)'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'single_weight', width: 100, title: '批号支重',
                        titleHelp: {message: '进仓单的支重'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 5})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'actual_ton_price_no_tax', width: 126, title: '实际吨价(不含税)',
                        titleHelp: {message: '(理计)：实际吨价(不含税)=销售开单的实际销售支价(不含税)/该批次的支重\n(非理计)：实际吨价(不含税)=销售开单的实际吨价'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'ton_price_tax', width: 100, title: '含税吨价',
                        titleHelp: {message: '含税吨价=实际吨价(不含税) * (1 + 销售税率)'},
                        formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                            }else{
                                return cellValue
                            }
                        },slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'tax_tate_stock_kd', width: 100, title: '销售税率',
                        titleHelp: {message: '销售开单的税率'},
                        formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return '0%'
                            }else{
                                return cellValue + '%'
                            }
                        },slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'tax_tate_stock_jc', width: 100, title: '进仓税率',
                        titleHelp: {message: '进仓单的税率'},
                        formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return '0%'
                            }else{
                                return cellValue + '%'
                            }
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'profit_total', width: 100, title: '总毛利',
                        titleHelp: {message: '总毛利 = 单位毛利 * 实际过磅重'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },
                    /*{
                        field:'has_pay', width: 100, title: '利息', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },*/
                    {
                        field:'profit_unit', width: 80, title: '单位毛利',
                        titleHelp: {message: '（订货）单位毛利 = 实际吨价(不含税) - 成本吨价 - 费用单价\n（非订货）单位毛利 = 实际吨价(不含税) - 每日成本吨价 - 费用单价'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'jc_cost_fees', width: 80, title: '费用单价',
                        titleHelp: {message: '费用单价 = 进仓单的(风割费 + 装车费 + 中转费 + 运费 + 手续费) / 进仓单的过磅重'},
                        /*formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        },*/ slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'category_class', width: 100, title: '分类',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'stock_bill_date_dd', width: 170, title: '订单日期',
                        formatter: ({cellValue}) => {
                            return this.$_formatDate(cellValue, 'yyyy-MM-dd HH:mm:ss')
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'create_date', width: 170, title: '限价日期',
                        formatter: ({cellValue}) => {
                            return this.$_formatDate(cellValue, 'yyyy-MM-dd HH:mm:ss')
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'cost_price', width: 80, title: '每天成本',
                        titleHelp: {message: '1、如果开单的仓库="厂一"和"厂—" 而且"是否订货"=否\n每天成本价在设置的基础上减10元\n2、如果是订单，每天成本显示0'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'cost_price_tax', width: 80, title: '每天成本(含税)',
                        titleHelp: {message: '每天成本(含税) = 每天成本 * (1 + 销售税率)'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'naked_into_warehouse_cost_jc_dun', width: 80, title: '成本',
                        titleHelp: {message: '进仓单成本 / 进仓单过磅重 = 成本（当进仓单批号：订货）'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                   },{
                        field:'naked_into_warehouse_cost_jc_dun_tax', width: 80, title: '含税成本',
                        titleHelp: {message: '含税成本=成本 * (1 + 进仓税率)'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'theory_weight', width: 80, title: '是否理计', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'weighing_heavy_actual', width: 100, title: '实际过磅重',
                        titleHelp: {message: '批号支重 * 销售开单支数 = 实际过磅重（当销售开单：理计）\n销售开单过磅重 = 实际过磅重'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'is_order', width: 80, title: '是否订货', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'polish_roll', width: 110, title: '是否打磨驳口', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceProfits/searchHeard', this.pickerDate)
                       }
                   },
                ],
                tableDataMain: [],
                filterData: {

               },
                sortData: {
                    "stock_bill_date_dd":"asc",
                },
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), new Date()],
                tableExport: {
                    // 默认选中类型
                    filename: '应收账款明细',
                    isMerge:true,
                    isFooter:true,
                    type: 'xlsx',
                    isColgroup: true,
                    // 自定义类型
                    types: ['xlsx', 'csv', 'html', 'xml', 'txt']
               },
           }
       },
        methods: {
            openTypeJC(row){
                if(row.bill_id_stock_jc.startsWith('JC')){
                    this.$_openType({path: '/intoTheWarehouse', query: {bill_id: row.bill_id_stock_jc}})
                }else{
                    this.$_openType({path: '/machining', query: {bill_id: row.bill_id_stock_jc}})
                }
            },
            mergeRowMethod({row, _rowIndex, column, visibleData}){
                /*{row: 1, col: 9, rowspan: 2, colspan: 1}*/
                const fields = ['sum_balance']
                const cellValue = row['customer']
                //console.log('column.property',column.property)
                if (cellValue && fields.includes(column.property)) {
                    const prevRow = visibleData[_rowIndex - 1]
                    console.log('prevRow',prevRow)
                    let nextRow = visibleData[_rowIndex + 1]
                    if (prevRow && prevRow['customer'] === cellValue) {
                        return {rowspan: 0, colspan: 0}
                   } else {
                        let countRowspan = 1
                        while (nextRow && nextRow['customer'] === cellValue) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                       }
                        if (countRowspan > 1) {
                            return {rowspan: countRowspan, colspan: 1}
                       }
                   }
               }
           },
            openReceipt(row){
                this.$router.push({path: '/receipt', query: {cash_type_id: row.cash_type_id,year:row.year,month:row.month,type:row.cash_type}})
           },

            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return this.$XEUtils.commafy(Number(obj), {digits: 2})
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.NumberAdd(count, item[field]);
               })
                return this.getNumberDefaultZero(count)
           },

            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'profit_total' || column.property === 'single_count'|| column.property === 'weighing_heavy_actual') {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
           searchProfits() {
                this.tablePage.currentPage = 1;
                this.getProfits();
           },
            getProfits() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/everyDayPriceProfits/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                        formData:this.formData
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        this.mergeCells = response.data.mergeCells
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
           handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getProfits();
           },
           tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },

       },computed: {

       },
        created() {

            this.getProfits();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }


    /deep/ .vxe-cell-help-icon.vxe-icon--question{
        display: -webkit-box !important;
        position: absolute !important;
    }
</style>
